/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
const REGEX_CLEAR_NUMBER_FIELD = /[\-$,/^\s+$/]/g;
const REGEX_ALPHANUMERIC = /^[ÑáéíóúÁÉÍÓÚÑña-zA-Z0-9 ]+$/;
const REGEX_REPLACE_FORMAT_CELLPHONE_NUMBER = /[\-_()/^\s+$/]/g;
const REGEX_TEXT_NO_SPECIAL_CHARACTERS = /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/;
const REGEX_TEXT_NO_NUMBERS = /^[a-zA-Z\u00C0-\u00FF\- ]+$/;
const REGEX_ONLY_NUMBERS = /^\d+$/;
const REGEX_ONLY_NUMBERS_NO_NEGATIVES = /^[0-9]+$/;
const REGEX_ONLY_NUMBERS_NO_NEGATIVES_GREATER_THAN_ZERO = /^[1-9][0-9]*$/;
const REGEX_ONLY_NUMBERS_1_9 = /^[1-9]\d*$/;
const REGEX_ONLY_NUMBER_0_9 = /\b\d(\b|\.|\?|\!)/;
const REGEX_ONLY_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const REGEX_TAX_ID = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const REGEX_URL_QUERY_STRING = /^[\w\-\._:/?#[\]!\$&'\(\)\*\+;=.]+$/;
const REGEX_CURRENCY = /^\$[0-9]+(\.[0-9]{1,2})?$/;
const REGEX_DECIMAL_NUMBERS = /^(\d*\.)?\d+$/;
const REGEX_PRODUCT_NAME_ALLOWED = /[^ÑáéíóúÁÉÍÓÚÑña-zA-Z0-9´–.,'"+®!¡¿?()\- ]/gi;
const REGEX_ONLY_UPPERCASE = /^[A-ZÁÉÍÓÚÑ]+$/;
const REGEX_ZIP_CODE_MX = /^[0-9]{5}$/;
const REGEX_ADDRESS_NUMBER = /^[A-Za-z0-9-\s\/]*$/;
const REGEX_HOURS_MINUTES = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const REGEX_ONLY_ONE_NUMBER_0_9 = /^\d$/;
const REGEX_VALID_URL = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;

export const regexTypes = {
  REGEX_CLEAR_NUMBER_FIELD,
  REGEX_ALPHANUMERIC,
  REGEX_REPLACE_FORMAT_CELLPHONE_NUMBER,
  REGEX_TEXT_NO_SPECIAL_CHARACTERS,
  REGEX_TEXT_NO_NUMBERS,
  REGEX_ONLY_NUMBERS,
  REGEX_ONLY_NUMBERS_NO_NEGATIVES,
  REGEX_ONLY_NUMBERS_NO_NEGATIVES_GREATER_THAN_ZERO,
  REGEX_ONLY_NUMBERS_1_9,
  REGEX_ONLY_NUMBER_0_9,
  REGEX_ONLY_EMAIL,
  REGEX_TAX_ID,
  REGEX_URL_QUERY_STRING,
  REGEX_CURRENCY,
  REGEX_DECIMAL_NUMBERS,
  REGEX_PRODUCT_NAME_ALLOWED,
  REGEX_ONLY_UPPERCASE,
  REGEX_ZIP_CODE_MX,
  REGEX_ADDRESS_NUMBER,
  REGEX_HOURS_MINUTES,
  REGEX_ONLY_ONE_NUMBER_0_9,
  REGEX_VALID_URL,
};

export function setFormatStringCellPhoneNumber(number) {
  const format = number?.replace(REGEX_REPLACE_FORMAT_CELLPHONE_NUMBER, '');
  let newNumber = format;
  // temporary validation
  if (!number || format === '52') {
    return '';
  }

  // temporary validation
  if (!number?.includes('+')) {
    newNumber = `52${format}`;
  }

  return `+${newNumber}`;
}

export function getFormatStringCellPhoneNumber(number) {
  const format = number?.substr(number.length - 10);

  return format;
}

export function clearFormatStringCellPhoneNumber(number) {
  return number?.replace(REGEX_REPLACE_FORMAT_CELLPHONE_NUMBER, '');
}

export function joinArrayString(values) {
  if (values?.length && Array.isArray(values)) {
    return values?.join(',');
  }
  return null;
}

export function splitArray(value) {
  if (value) {
    return value?.split(',');
  }
  return [];
}

export function extractUuids(values) {
  const uuidsLis = [];

  if (values?.length) {
    for (let value of values) {
      uuidsLis.push(value?.uuid);
    }
  }

  return uuidsLis;
}

export function clearValue({ value = null, prefix = '$' }) {
  return value?.replace(prefix, '');
}

export function clearValueV2({ value = null, rex = regexTypes.REGEX_CLEAR_NUMBER_FIELD }) {
  if (typeof value === 'string') return value?.replace(rex, '');
  return value;
}

function cleanPrice(srt = '') {
  let newStr = srt.replace('$', '0');
  return newStr.replace(/\,/g, '');
}

const PERSON = 'person';
const COMPANY = 'company';

export const rfcTypes = {
  PERSON,
  COMPANY,
};

export function validateTaxId(taxId) {
  const type = (() => {
    if (taxId?.length === 12) return COMPANY;
    if (taxId?.length === 13) return PERSON;
    return null;
  })();

  return { isValid: taxId?.match(REGEX_TAX_ID) !== null, type };
}

export function clearUrl(string) {
  const urlPrefix = string?.replace('https://', '');
  return urlPrefix;
}

export function priceStringToNumber(price) {
  if (price && typeof price === 'string') {
    return Number(cleanPrice(price));
  }
  if (!price) return 0;
  return price;
}

export function formatTextAllowedValues(value, regex) {
  return value?.replace(regex, '');
}

export const renderSafeValue = ({ value, renderValue = value, t }) => {
  return value ? renderValue : t('common:notAvailableDefault');
};
